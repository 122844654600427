@tailwind base;
@tailwind components;
@tailwind utilities;



.dot{
	transition-duration: 300ms;
	transition-timing-function: cubic-bezier(.89,.2,.22,.77);
	left: 0px;
}
 
.dot.dot-active{
	transform: translateX(50%);
}